// app/javascript/controllers/product_options_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["typeSelect", "multiValueContainer", "container", "template", "adjustPrice", "priceOptions","removeLink", "valuesLabel"];

  connect() {
    this.toggleMultiValueFields();
    this.adjustPriceTargets.forEach((checkbox, index) => {
      this.togglePriceOptionsForCheckbox(checkbox); // Call for each adjustPrice checkbox on load
    });
    this.updateValuesLabel(); // Update the label on page load
  }

  toggleMultiValueFields() {
    const optionType = this.typeSelectTarget.value;
    const multiValueOptionTypes = ['dropdown',  'radio_button', 'list', 'swatches'];
    const isMultiValueOption = multiValueOptionTypes.includes(optionType);

    if (isMultiValueOption) {
      this.multiValueContainerTarget.style.display = '';
    } else {
      this.multiValueContainerTarget.style.display = 'none';
    }

    this.toggleRemoveLinks(isMultiValueOption);
    this.updateValuesLabel();
  }

  togglePriceOptions(event) {
    const checkbox = event.target;
    this.togglePriceOptionsForCheckbox(checkbox);
  }

  togglePriceOptionsForCheckbox(checkbox) {
    const priceOptions = checkbox.closest('.nested-fields').querySelector('[data-product-options-target="priceOptions"]');
    if (checkbox.checked) {
      priceOptions.style.display = 'flex';
    } else {
      priceOptions.style.display = 'none';
    }
  }

  toggleRemoveLinks(show) {
    this.removeLinkTargets.forEach((link) => {
      link.style.display = show ? '' : 'none';
    });
  }

  addOptionValue(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.containerTarget.insertAdjacentHTML("beforeend", content);
    this.toggleMultiValueFields(); // Ensure correct field visibility for newly added records
  }

  removeOptionValue(event) {
    event.preventDefault();
    let wrapper = event.target.closest(".nested-fields");
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = "1";
      wrapper.style.display = "none";
    }
  }

  updateValuesLabel() {
    const optionType = this.typeSelectTarget.value;
    if (optionType === 'text_input') {
      this.valuesLabelTarget.textContent = 'Placeholder';
    } else {
      this.valuesLabelTarget.textContent = 'Values';
    }
  }
}